<template v-if="showCookiePopup">
  <div
    class="cookie-popup"
    :class="{ 'cookie-popup-hide': !showCookiePopup }"
  >
    <div class="cookie-notice">
      <p>{{ cookieContent.disclaimer }}</p>
    </div>

    <div
      class="cookie-options cookie-options-hide"
      ref="cookieOptions"
    >
      <div class="options-select">
        <div>{{ cookieContent.necessary_cookie }}</div>

        <div class="cookie-no-switch">
          <input
            type="checkbox"
            name="cookie"
            id="necessaryCookie"
            value="necessary"
            checked
            v-model="acceptedCookies"
          >
          <label for="necessaryCookie">{{ cookieContent.always_active }}</label>
        </div>
      </div>

      <div class="options-select">
        <div>{{ cookieContent.statistic_cookie }}</div>

        <div class="cookie-switch">
          <input
            type="checkbox"
            name="cookie"
            id="statisticsCookie"
            value="statistics"
            v-model="acceptedCookies"
          >
          <label for="statisticsCookie"></label>
        </div>
      </div>

      <div class="options-select">
        <div>{{ cookieContent.marketing_cookie }}</div>

        <div class="cookie-switch">
          <input
            type="checkbox"
            name="cookie"
            id="marketingCookie"
            value="marketing"
            v-model="acceptedCookies"
          >
          <label for="marketingCookie"></label>
        </div>
      </div>
    </div>

    <div class="cookie-buttons">
      <button
        class="cookie-button cookie-agree-button"
        type="button"
        ref="accept-all-cookies"
        @click.prevent="acceptAllCookies"
      >{{ cookieContent.accept }}</button>

      <button
        class="cookie-button cookie-settings-button"
        type="button"
        ref="customise-cookies"
        @click.prevent="customiseCookies"
      >{{ cookieContent.settings }}</button>

      <button
        class="cookie-button cookie-agree-button cookie-button-hide"
        type="button"
        ref="select-accept-all-cookies"
        @click.prevent="acceptAllCookies"
      >{{ cookieContent.accept_all }}</button>

      <button
        class="cookie-button cookie-settings-button cookie-button-hide"
        type="button"
        ref="accept-selected-cookies"
        @click.prevent="acceptSelectedCookies"
      >{{ cookieContent.accept_selected }}</button>
    </div>

    <router-link
        class="cookie-privacy-link"
        :to="{ name: 'privacy' }"
      >Prywatnosci</router-link>
  </div>
</template>

<script>
export default {
  name: 'TheCookieNotice',
  props: ['cookieContent'],
  data () {
    return {
      showCookiePopup: false,
      acceptedCookies: []
    }
  },
  methods: {
    setCookies (name, value, days) {
      const d = new Date()
      d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days)
      document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}`
    },
    getCookies (name) {
      const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`)
      return v ? v[2] : null
    },
    deleteCookies (name) {
      this.setCookies(name, '', -1)
    },
    updateTags (cookies) {
      const googleSettings = {}
      const bingSettings = {}

      cookies.forEach(function (cookie) {
        if (cookie === 'marketing') {
          googleSettings.ad_storage = 'granted'
          bingSettings.ad_storage = 'granted'
        } else if (cookie === 'statistics') {
          googleSettings.analytics_storage = 'granted'
        }
      })

      if (
        Object.prototype.hasOwnProperty.call(googleSettings, 'ad_storage') || Object.prototype.hasOwnProperty.call(googleSettings, 'analytics_storage')
      ) {
        if (typeof gtag !== 'undefined') {
          gtag('consent', 'update', googleSettings)
        }
      } else if (
        Object.prototype.hasOwnProperty.call(bingSettings, 'ad_storage')
      ) {
        window.uetq = window.uetq || []
        window.uetq.push('consent', 'update', bingSettings)
      }
    },
    acceptAllCookies () {
      this.acceptedCookies = ['necessary', 'statistics', 'marketing']

      this.updateTags(this.acceptedCookies)
      this.setCookies('cookies-accepted', this.acceptedCookies, 364)
      this.showCookiePopup = false
    },
    customiseCookies () {
      const buttons = [
        this.$refs['accept-all-cookies'],
        this.$refs['customise-cookies'],
        this.$refs['select-accept-all-cookies'],
        this.$refs['accept-selected-cookies']
      ]

      buttons.forEach(button => {
        if (button.classList.contains('cookie-button-hide')) {
          button.classList.remove('cookie-button-hide')
        } else {
          button.classList.add('cookie-button-hide')
        }
      })

      this.$refs.cookieOptions.classList.remove('cookie-options-hide')
      this.acceptedCookies.push('necessary')
    },
    acceptSelectedCookies () {
      this.updateTags(this.acceptedCookies)
      this.setCookies('cookies-accepted', this.acceptedCookies, 364)
      this.showCookiePopup = false
    }
  },
  created () {
    if (this.getCookies('cookies-accepted') === null) {
      this.showCookiePopup = true
    } else {
      this.acceptedCookies = this.getCookies('cookies-accepted').split(',')
      this.updateTags(this.acceptedCookies)
    }
  }
}
</script>

<style scoped>
/* cookie disclaimer */
.cookie-popup {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.45);
  color: #fff;
  font-size: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  line-height: 1.2;
  padding: 10px;
  z-index: 99999;
  background-color: #666;
  opacity: 1;
  text-align: center;
  text-shadow: none;
  transition: opacity 1s linear;
}

.cookie-popup.cookie-popup-hide,
.cookie-options.cookie-options-hide,
.cookie-button.cookie-button-hide {
  display: none;
  opacity: 0;
}

.cookie-notice,
.cookie-options,
.cookie-buttons {
  width: 100%;
}

.cookie-notice p {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 8px;
}

.cookie-options {
  opacity: 1;
  transition: opacity 1s linear;
}

.cookie-privacy-link {
  color: #ed1254;
  font-size: 14px;
}

.cookie-button {
  display: block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  user-select: none;
  padding: 6px 12px;
  font-size: 13px;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, opacity 0.45s linear;
  margin-bottom: 8px;
  width: 100%;
}

.cookie-button:hover,
.cookie-button:focus {
  text-decoration: none;
}

.cookie-agree-button {
  color: #fff;
  background-color: #a60c3b;
  border: 1px solid #a60c3b;
}

.cookie-agree-button:hover,
.cookie-agree-button:focus {
  background-color: #ed1254;
  color: #fff;
  border-color: #ed1254;
}

.cookie-agree-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(205, 16, 74, 0.5);
}

.cookie-settings-button {
  color: #666;
  background-color: #fff;
  border: 1px solid #fff;
}

.cookie-settings-button:hover,
.cookie-settings-button:focus {
  background-color: #ccc;
  color: #333;
  border-color: #ccc;
}

.cookie-settings-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(204, 204, 204, 0.5);
}

.cookie-options {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #000;
  text-align: left;
  margin-bottom: 8px;
}

.options-select {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  line-height: 1.5;
  padding: 8px 16px;
  border-bottom: 1px solid #ddd;
}

.options-select:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.options-select:last-child {
  border-bottom: 0;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
/* The switch - the box around the slider */
.cookie-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-bottom: 0;
  right: 0;
}
/* Hide default HTML checkbox */
.cookie-switch input,
.cookie-no-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.cookie-switch > label {
  border-radius: 26px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.cookie-switch > label::before {
  border-radius: 50%;
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: #fff;
  transition: 0.4s;
}

.cookie-switch input:checked + label {
  background-color: #ed1254;
}

.cookie-switch input:focus + label {
  box-shadow: 0 0 1px #ed1254;
}

.cookie-switch input:checked + label::before {
  transform: translateX(20px);
}
/* media break points */
@media (min-width: 768px) {
  .cookie-popup {
    padding: 10px 50px;
  }

  .cookie-notice {
    width: 80%;
  }

  .cookie-options {
    margin: 0 auto 8px auto;
    max-width: 500px;
  }

  .cookie-button {
    display: inline-block;
    max-width: 200px;
  }
}

@media (min-width: 992px) {
  .cookie-popup {
    padding: 10px 100px;
  }
}
</style>
