<template>
  <div class="offcanvas offcanvas-end bg-dark opacity-75" tabindex="-1" id="mainNav" aria-labelledby="mainNavLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title text-white" id="mainNavLabel">
        <strong>Citasocasionales.es</strong>
      </h5>

      <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>

    <div class="offcanvas-body">
      <nav class="nav flex-column">
        <router-link
          class="nav-link"
          :to="{ name: 'home' }"
          @click="closeNav"
          active-class="active"
          exact-active-class=""
        >
          <strong>Página principal</strong>
        </router-link>

        <hr class="text-white my-0">

        <router-link
          class="nav-link"
          :to="{ name: 'table' }"
          @click="closeNav"
          active-class="active"
          exact-active-class=""
        >
          <strong>Tabla</strong>
        </router-link>

        <hr class="text-white my-0">

        <router-link
          class="nav-link"
          :to="{ name: 'gay' }"
          @click="closeNav"
          active-class="active"
          exact-active-class=""
        >
          <strong>Gay</strong>
        </router-link>

        <hr class="text-white my-0">
      </nav>
    </div>
  </div>
</template>

<script>
import { Offcanvas } from 'bootstrap'

export default {
  name: 'TheOffcanvas',
  methods: {
    closeNav () {
      const mainNav = document.getElementById('mainNav')
      Offcanvas.getInstance(mainNav).hide()
    }
  }
}
</script>
