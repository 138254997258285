<template>
  <main>
    <section class="bg-hero-table">
      <div class="container">
        <div class="d-flex flex-column justify-content-center text-lg-center pt-4">
          <h1 class="fs-2">Comparación de sitios de citas</h1>

          <p class="mb-4">Cada día nacen nuevos sitios de citas. Algunos especializados, otros no. Algunos de pago, otros gratuitos. Así que con esta enorme selección para elegir, es difícil hacer la elección correcta. Hemos estudiado para usted cada especificidad de los cuatro mejores sitios de citas para que pueda hacer la mejor elección entre todas estas propuestas.</p>

          <h2>Tabla comparativa</h2>

          <p>A continuación le presentamos una tabla comparativa que le permitirá comprender mejor las diferencias entre cada sitio de citas:</p>
        </div>
      </div>
    </section>

    <section class="container">
      <div class="row">
        <div class="col-12">
          <h3 class="d-lg-none mb-3">Top 5 sitios de citas</h3>

          <SiteOfferMobileTable
            v-for="site in sites"
            :key="site.id"
            :site="site"
            :getSiteLogoSource="getSiteLogoSource"
            :getSiteImageSource="getSiteImageSource"
            :collapsable="(site.id !== 1) ? true : false"
            :applyIconStyle="applyIconStyle"
          />

          <table class="d-none d-lg-table table table-desktop table-striped bg-white text-center shadow-lg mb-5 mt-n5 p-3">
            <thead>
              <tr class="bg-black text-white">
                <th scope="col">Top 5 sitios de citas</th>

                <th
                  scope="col"
                  v-for="site in sites"
                  :key="site.id"
                >{{ site.title }}</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td scope="row"></td>

                <td
                  v-for="site in sites"
                  :key="site.id"
                >
                  <a
                    class="d-block"
                    :href="site.domain_url"
                    :data-site-id="site.data_id"
                  >
                    <img
                      class="img-fluid"
                      :src="getSiteLogoSource(site.id)"
                      :alt="site.title"
                    >
                  </a>
                </td>
              </tr>

              <tr>
                <th scope="row">Audiencia</th>

                <td
                  v-for="i in 3"
                  :key="i"
                ></td>
              </tr>

              <tr>
                <td scope="row">Rango de edad</td>

                <td
                  :class="{ 'fw-bold': (site.id === 1) }"
                  v-for="site in sites"
                  :key="site.id"
                >{{ site.age_range }}</td>
              </tr>

              <tr>
                <td scope="row">Miembros activos</td>

                <td
                  :class="{ 'fw-bold': (site.id === 1) }"
                  v-for="site in sites"
                  :key="site.id"
                >{{ site.active_members }}</td>
              </tr>

              <tr>
                <td scope="row">Hombres mujeres</td>

                <td
                  :class="{ 'fw-bold': (site.id === 1) }"
                  v-for="site in sites"
                  :key="site.id"
                >{{ site.men_women }}</td>
              </tr>

              <tr>
                <th scope="row">Funcionalidad</th>

                <td
                  v-for="i in 3"
                  :key="i"
                ></td>
              </tr>

              <tr>
                <td scope="row">Registro gratis</td>

                <td
                  class="fs-3 fw-bold"
                  :class="applyIconStyle(site.free_registration['style'])"
                  v-for="site in sites"
                  :key="site.id"
                  v-html="site.free_registration['icon']"
                ></td>
              </tr>

              <tr>
                <td scope="row">Prueba gratis</td>

                <td
                  class="fs-3 fw-bold"
                  :class="applyIconStyle(site.try_for_free['style'])"
                  v-for="site in sites"
                  :key="site.id"
                  v-html="site.try_for_free['icon']"
                ></td>
              </tr>

              <tr>
                <td scope="row">Sugerencia de perfiles</td>

                <td
                  class="fs-3 fw-bold"
                  :class="applyIconStyle(site.profile_suggestions['style'])"
                  v-for="site in sites"
                  :key="site.id"
                  v-html="site.profile_suggestions['icon']"
                ></td>
              </tr>

              <tr>
                <td scope="row">Buscar por criterios</td>

                <td
                  class="fs-3 fw-bold"
                  :class="applyIconStyle(site.search_criteria['style'])"
                  v-for="site in sites"
                  :key="site.id"
                  v-html="site.search_criteria['icon']"
                ></td>
              </tr>

              <tr>
                <td scope="row">Móvil</td>

                <td
                  class="fs-3 fw-bold"
                  :class="applyIconStyle(site.mobile['style'])"
                  v-for="site in sites"
                  :key="site.id"
                  v-html="site.mobile['icon']"
                ></td>
              </tr>

              <tr>
                <td scope="row">Comunicación</td>

                <td
                  class="fs-3 fw-bold"
                  :class="applyIconStyle(site.communication['style'])"
                  v-for="site in sites"
                  :key="site.id"
                  v-html="site.communication['icon']"
                ></td>
              </tr>

              <tr>
                <td scope="row">Chat</td>

                <td
                  class="fs-3 fw-bold"
                  :class="applyIconStyle(site.chat['style'])"
                  v-for="site in sites"
                  :key="site.id"
                  v-html="site.chat['icon']"
                ></td>
              </tr>

              <tr>
                <td scope="row">Cámara web</td>

                <td
                  class="fs-3 fw-bold"
                  :class="applyIconStyle(site.webcam['style'])"
                  v-for="site in sites"
                  :key="site.id"
                  v-html="site.webcam['icon']"
                ></td>
              </tr>

              <tr>
                <th scope="row">Puntos clave</th>

                <td
                  v-for="i in 3"
                  :key="i"
                ></td>
              </tr>

              <tr>
                <td scope="row">Seguridad</td>

                <td
                  :class="{ 'fw-bold': (site.id === 1) }"
                  v-for="site in sites"
                  :key="site.id"
                >{{ site.security }}</td>
              </tr>

              <tr>
                <td scope="row">Ergonomía</td>

                <td
                  :class="{ 'fw-bold': (site.id === 1) }"
                  v-for="site in sites"
                  :key="site.id"
                >{{ site.ergonomics }}</td>
              </tr>

              <tr>
                <td scope="row">Servicio al Cliente</td>

                <td
                  :class="{ 'fw-bold': (site.id === 1) }"
                  v-for="site in sites"
                  :key="site.id"
                >{{ site.customer_service }}</td>
              </tr>

              <tr>
                <th scope="row">Precios</th>

                <td
                  v-for="i in 3"
                  :key="i"
                ></td>
              </tr>

              <tr>
                <td scope="row">Modo operativo</td>

                <td
                  :class="{ 'fw-bold': (site.id === 1) }"
                  v-for="site in sites"
                  :key="site.id"
                >{{ site.operating_mode }}</td>
              </tr>

              <tr>
                <td scope="row"></td>

                <td
                  v-for="site in sites"
                  :key="site.id"
                >
                  <div class="fs-2 fw-bold text-danger mb-3">{{ site.rating }}</div>

                  <a
                    class="btn btn-warning w-100 mb-3"
                    :href="site.domain_url"
                    :data-site-id="site.data_id"
                  >Visitar el sitio</a>

                  <a
                    class="d-block w-100"
                    :href="site.domain_url"
                    :data-site-id="site.data_id"
                  >
                    <img
                      class="img-fluid"
                      :src="getSiteLogoSource(site.id)" alt="Snapdate.es">
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          <h3>Cuatro consejos para elegir el sitio de citas que más le convenga:</h3>

          <ol class="mb-4">
            <li>El primer paso crucial para elegir su sitio de citas es identificar sus deseos para encontrar un sitio de citas que se acerque a ellos. En efecto, nada es más importante que lo que usted busca, su objetivo define este paso. Es por ello que debe saber lo que busca y hacerse esta pregunta. Recurrir a un sitio de citas que se adapte a ti te ayudará a encontrar más fácilmente a las personas que te gustan y con las que tienes más en común.</li>
            <li>En segundo lugar, asegúrese de elegir un sitio de citas que se ajuste a su rango de edad. En efecto, si tienes 65 años pero te registras en un sitio de citas que tiene un grupo de edad más joven, será más difícil encontrar el alma gemela, y viceversa. Por lo tanto, es importante fijarse en el rango de edad al que se dirige un sitio de citas antes de registrarse.</li>
            <li>Elige un sitio de citas que se adapte a ti personalmente. Infórmese sobre los precios o tarifas, pero también sobre las condiciones de uso para hacer la elección que mejor se adapte a sus necesidades. También es importante sentirse cómodo conla interfaz del sitio de citas, ya que es aquí donde intercambiará con una pareja potencial. Si desea utilizar una tableta para interactuar con sus futuras parejas, preste atención a la compatibilidad del sitio en este soporte.</li>
            <li>Por último, no dude en probar los sitios de citas. Sin embargo, tenga cuidado de no multiplicar los perfiles en la mayoría de lossitios de citas porque esto podría ser notado por los usuarios y desanimarlos. Por otro lado, puedes estar muy bien registrado en dos o tres sitios de citas diferentes para tener más posibilidades de encontrar el gran amor o la pasión. ¡Buena suerte!</li>
          </ol>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import SiteOfferMobileTable from '@/components/SiteOfferMobileTable.vue'

export default {
  name: 'Table',
  props: ['sites', 'gaySites', 'getThisSite', 'getSiteLogoSource', 'getSiteImageSource', 'getTodayDate'],
  components: {
    SiteOfferMobileTable
  },
  methods: {
    applyIconStyle (hasStyle) {
      let applyStyle = ''
      switch (hasStyle) {
        case true:
          applyStyle = 'text-success'
          break
        case false:
          applyStyle = 'text-danger'
          break
        case null:
          applyStyle = 'text-warning'
          break
        default:
          break
      }
      return applyStyle
    }
  }
}
</script>
