<template>
  <div class="card mb-1">
    <div class="card-body px-2 pt-3 pb-2 text-center">
      <a
        class="d-block position-relative"
        :href="site.domain_url"
        :data-site-id="site.data_id"
      >
        <div class="aside-overlay-opacity position-absolute h-100 w-100 bg-white rounded"></div>

        <div class="triangle triangle-primary position-absolute triangle-rounded">
          <span class="aside-number-position position-absolute text-white fs-3 fw-bold">{{ site.rank_number }}</span>
        </div>

        <img
          class="img-fluid rounded"
          :src="getSiteImageSource(site.id)"
          :alt="site.title"
        >

        <div class="aside-rating text-danger position-absolute">
          <span class="fs-1 fw-bold">{{ site.rating }}</span>/<span class="fw-bold">10</span>
        </div>

        <div class="aside-stars position-absolute text-nowrap">
          <img
            class="star"
            src="../assets/img/star.png"
            :alt="site.title"
            v-for="i in parseInt(site.stars)"
            :key="i"
          >
        </div>

        <div class="aside-logo bottom-0 position-absolute">
          <img
            class="img-fluid"
            :src="getSiteLogoSource(site.id)"
            :alt="site.title">
        </div>
      </a>

      <div class="d-flex align-items-center justify-content-center">
        <router-link
          class="link-danger"
          :to="{ name: site.name }"
        >Revisar</router-link>

        <span class="align-middle">&VerticalSeparator;</span>

        <a
          class="link-danger"
          :href="site.domain_url"
          :data-site-id="site.data_id"
        >Regístrate</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SiteOfferAsideCard',
  props: ['site', 'getSiteLogoSource', 'getSiteImageSource']
}
</script>
