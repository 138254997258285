<template>
  <aside class="col-md-4">
    <div class="sticky-top mb-3">
      <div class="card mb-4">
        <div class="card-body p-1">
          <a
            class="d-block position-relative"
            :href="site.domain_url"
            :data-site-id="site.data_id"
          >
            <div class="triangle triangle-primary position-absolute">
              <span class="aside-number-position position-absolute text-white fs-3 fw-bold">{{ site.rank_number }}</span>
            </div>

            <img
              class="img-fluid rounded"
              :src="getSiteImageSource(site.id)"
              :alt="site.title"
            >

            <div class="position-absolute aside-badge-logo bg-warning text-center bottom-0 start-0 end-0">
              <img
                class="img-fluid"
                :src="getSiteLogoSource(site.id)"
                :alt="site.title">
            </div>
          </a>

          <hr>

          <div class="border d-flex justify-content-center text-center">
            <div class="border-end px-2">
              <div class="mb-2">Nuestra puntuación</div>
              <div class="fs-1 fw-bold lh-lg">{{ site.rating }}</div>
            </div>

            <div class="px-2">
              <div class="mb-2">Opiniones de los usuarios</div>
              <div class="lh-lg text-nowrap">
                <span
                  v-for="i in parseInt(site.stars)"
                  :key="i"
                >
                  <i class="bi-star-fill fs-5 text-warning"></i>

                  <span v-if="i < parseInt(site.stars)">&ensp;</span>
                </span>
              </div>
            </div>
          </div>

          <hr>

          <div class="d-flex justify-content-center">
            <a
              class="btn btn-warning shadow w-75 mb-2"
              :href="site.domain_url"
              :data-site-id="site.data_id"
            >Visitar la web</a>
          </div>
        </div>
      </div>

      <div class="bg-danger p-2 mb-2">
        <h5 class="text-center text-white mb-0">3 primeros</h5>
      </div>

      <SiteOfferAsideCard
        v-for="site in sites"
        :key="site.id"
        :site="site"
        :getSiteLogoSource="getSiteLogoSource"
        :getSiteImageSource="getSiteImageSource"
      />
    </div>
  </aside>
</template>

<script>
import SiteOfferAsideCard from '@/components/SiteOfferAsideCard.vue'

export default {
  name: 'SiteOfferAside',
  props: ['sites', 'site', 'getSiteLogoSource', 'getSiteImageSource'],
  components: {
    SiteOfferAsideCard
  }
}
</script>
