<template>
  <SiteOfferReview
    :sites="gaySites"
    :site="getThisSite(4)"
    :getSiteLogoSource="getSiteLogoSource"
    :getSiteImageSource="getSiteImageSource"
  />
</template>

<script>
import SiteOfferReview from '@/components/SiteOfferReview'

export default {
  name: 'Gaypartner',
  props: ['sites', 'gaySites', 'getThisSite', 'getSiteLogoSource', 'getSiteImageSource', 'getTodayDate'],
  components: {
    SiteOfferReview
  }
}
</script>
