<template>
  <main>
    <section class="bg-hero-gay">
      <div class="container">
        <div class="d-flex flex-column justify-content-center text-center pt-4">
          <h1 class="fs-2 text-white">Ranking de los mejores sitios de citas gay &check; <span class="text-capitalize">{{ getTodayDate }}</span></h1>

          <p class="d-none d-lg-block lead fw-normal text-white">¿Buscas encuentros sensuales y liberales? ¡Entonces este ranking es para ti! ¡Gracias a la evaluación de nuestro equipo y la opinión de los internautas, te presentamos la lista de los mejores sitios web gay para adultos para que finalmente logres encontrar a tu pareja ideal! ¡Compara, prueba y encuentra a tu pareja!</p>
        </div>
      </div>
    </section>

    <section class="container">
      <div class="row">
        <div class="col-12">
          <table class="table bg-white shadow-lg mb-4 mt-n7">
            <thead class="text-center">
              <tr class="bg-black text-white">
                <th scope="col">Sitio web</th>
                <th class="d-none d-md-table-cell" scope="col">Ventajas</th>
                <th class="d-none d-lg-table-cell" scope="col">Miembros</th>
                <th class="d-none d-md-table-cell" scope="col">Puntuaci</th>
                <th scope="col">Informaci</th>
              </tr>
            </thead>

            <tbody>
              <!-- First Site -->
              <tr class="table-danger">
                <th scope="row">
                  <a
                    class="d-block position-relative"
                    :href="gaySites[0].domain_url"
                    :data-site-id="gaySites[0].data_id"
                  >
                    <div class="triangle triangle-primary position-absolute">
                      <span class="number-position position-absolute text-white fs-3 fw-bold">{{ gaySites[0].rank_number }}</span>
                    </div>

                    <img
                      class="img-fluid"
                      :src="getSiteImageSource(gaySites[0].id)"
                      :alt="gaySites[0].title"
                    >

                    <div class="position-absolute badge-logo badge-logo-gay-first">
                      <img
                        class="img-fluid"
                        :src="getSiteLogoSource(gaySites[0].id)"
                        :alt="gaySites[0].title"
                      >
                    </div>
                  </a>
                </th>

                <td class="d-none d-md-table-cell">
                  <ul class="list-check">
                    <li
                      v-for="(item, i) in gaySites[0].usps"
                      :key="i"
                      v-html="item"
                    ></li>
                  </ul>
                </td>

                <td class="d-none d-lg-table-cell">
                  <p><span class="text-danger"><strong>{{ gaySites[0].active_members }}</strong></span> solteros</p>

                  <p>Rango de edad:<br><span class="text-danger"><strong>{{ gaySites[0].age_range }}</strong></span></p>
                </td>

                <td class="d-none d-md-table-cell text-center">
                  <div class="text-danger fs-2 fw-bold">{{ gaySites[0].rating }}</div>
                  <div class="text-danger">{{ gaySites[0].number_of_user_rates }}</div>
                </td>

                <td>
                  <div class="d-sm-none">
                    <ul class="list-check mb-1">
                      <li v-html="gaySites[0].usps[0]"></li>
                      <li v-html="gaySites[0].usps[1]"></li>
                    </ul>

                    <div class="text-center mb-1">
                      <i class="bi-star-fill text-warning"></i>&ensp;{{ `${gaySites[0].rating} ${gaySites[0].number_of_user_rates}` }}
                    </div>
                  </div>

                  <div class="text-center mb-3">
                    <a
                      class="btn btn-warning shadow text-nowrap"
                      :href="gaySites[0].domain_url"
                      :data-site-id="gaySites[0].data_id"
                    >Visita el sitio</a>
                  </div>

                  <div class="d-none d-sm-block text-center">
                    <router-link
                      class="text-decoration-none text-dark"
                      :to="{ name: gaySites[0].name }"
                    >Saber más</router-link>
                  </div>
                </td>
              </tr>
              <!-- Second Site -->
              <tr>
                <th scope="row">
                  <a
                    class="d-block position-relative"
                    :href="gaySites[1].domain_url"
                    :data-site-id="gaySites[1].data_id"
                  >
                    <div class="triangle triangle-primary position-absolute">
                      <span class="number-position position-absolute text-white fs-3 fw-bold">{{ gaySites[1].rank_number }}</span>
                    </div>

                    <img
                      class="img-fluid"
                      :src="getSiteImageSource(gaySites[1].id)"
                      :alt="gaySites[1].title"
                    >

                    <div class="position-absolute badge-logo badge-logo-gay-second">
                      <img
                        class="img-fluid"
                        :src="getSiteLogoSource(gaySites[1].id)"
                        :alt="gaySites[1].title"
                      >
                    </div>
                  </a>
                </th>

                <td class="d-none d-md-table-cell">
                  <ul class="list-check">
                    <li
                      v-for="(item, i) in gaySites[1].usps"
                      :key="i"
                      v-html="item"
                    ></li>
                  </ul>
                </td>

                <td class="d-none d-lg-table-cell">
                  <p><span class="text-danger"><strong>{{ gaySites[1].active_members }}</strong></span> solteros</p>
                  <p>Rango de edad:<br><span class="text-danger"><strong>{{ gaySites[1].age_range }}</strong></span></p>
                </td>

                <td class="d-none d-md-table-cell text-center">
                  <div class="text-danger fs-2 fw-bold">{{ gaySites[1].rating }}</div>
                  <div class="text-danger">{{ gaySites[1].number_of_user_rates }}</div>
                </td>

                <td>
                  <div class="d-sm-none">
                    <ul class="list-check mb-1">
                      <li v-html="gaySites[1].usps[0]"></li>
                    </ul>

                    <div class="text-center mb-1">
                      <i class="bi-star-fill text-warning"></i>&ensp;{{ `${gaySites[1].rating} ${gaySites[1].number_of_user_rates}` }}
                    </div>
                  </div>

                  <div class="text-center mb-3">
                    <a
                      class="btn btn-warning shadow text-nowrap"
                      :href="gaySites[1].domain_url"
                      :data-site-id="gaySites[1].data_id"
                    >Visita el sitio</a>
                  </div>

                  <div class="d-none d-sm-block text-center">
                    <router-link
                      class="text-decoration-none text-dark"
                      :to="{ name: gaySites[1].name }"
                    >Saber más</router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'Gay',
  props: ['sites', 'gaySites', 'getThisSite', 'getSiteLogoSource', 'getSiteImageSource', 'getTodayDate']
}
</script>
