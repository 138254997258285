import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Table from '../views/Table.vue'
import Privacy from '../views/Privacy.vue'
import Snapdate from '../views/Snapdate.vue'
import Vecinassecretas from '../views/Vecinassecretas.vue'
import Encuentratuchica from '../views/Encuentratuchica.vue'
import Gay from '../views/Gay.vue'
import Gaygrinder from '../views/Gaygrinder.vue'
import Gaypartner from '../views/Gaypartner.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/tabla/',
    name: 'table',
    component: Table
  },
  {
    path: '/privacidad/',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/snapdate/',
    name: 'snapdate',
    component: Snapdate
  },
  {
    path: '/vecinassecretas/',
    name: 'vecinassecretas',
    component: Vecinassecretas
  },
  {
    path: '/encuentratuchica/',
    name: 'encuentratuchica',
    component: Encuentratuchica
  },
  {
    path: '/gay/',
    name: 'gay',
    component: Gay
  },
  {
    path: '/gay/gaygrinder/',
    name: 'gaygrinder',
    component: Gaygrinder
  },
  {
    path: '/gay/gaypartner/',
    name: 'gaypartner',
    component: Gaypartner
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from) {
    return {
      behavior: 'smooth',
      top: 0
    }
  }
})

export default router
