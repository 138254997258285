<template>
  <button
    class="btn btn-danger btn-scroll-top"
    type="button"
    v-show="hasScrolled"
    @click="scrollToTop"
  >
    <i class="bi bi-arrow-up-short"></i>
  </button>
</template>

<script>
export default {
  name: 'TheScrollTopButton',
  data () {
    return {
      hasScrolled: false
    }
  },
  methods: {
    scrollToTop () {
      const rootElement = document.documentElement
      rootElement.scrollTo({
        behavior: 'smooth',
        top: 0
      })
    },
    showButton () {
      // Show button when scrolled 80% down
      // the total height of the page
      const rootElement = document.documentElement
      const scrollTotal = rootElement.scrollHeight - rootElement.clientHeight
      this.hasScrolled = (rootElement.scrollTop / scrollTotal) > 0.80
    }
  },
  created () {
    window.addEventListener('scroll', this.showButton)
  },
  unmounted () {
    window.removeEventListener('scroll', this.showButton)
  }
}
</script>
