<template>
  <SiteOfferReview
    :sites="sites"
    :site="getThisSite(2)"
    :getSiteLogoSource="getSiteLogoSource"
    :getSiteImageSource="getSiteImageSource"
  />
</template>

<script>
import SiteOfferReview from '@/components/SiteOfferReview'

export default {
  name: 'Encuentratuchica',
  props: ['sites', 'gaySites', 'getThisSite', 'getSiteLogoSource', 'getSiteImageSource', 'getTodayDate'],
  components: {
    SiteOfferReview
  }
}
</script>
