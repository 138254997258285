<template>
  <main>
    <div class="privacy-hero">
      <div class="bg-privacy-hero"></div>
    </div>

    <section class="container privacy-container bg-white position-relative mb-4">
      <div class="row">
        <div class="col-12">
          <h1 class="mt-3"><strong>Declaracion de privacidad</strong></h1>

          <hr>

          <p>Citasocasionales.es toma la importancia y el cuidado en el manejo de los datos personales. Por lo tanto, procesamos y aseguramos los datos personales. Al procesar datos personales, cumplimos con los requisitos del Reglamento general de protección de datos (GDPR).</p>

          <p>Citasocasionales.es es responsable del tratamiento de los datos personales tal y como se muestra en esta declaración de privacidad.</p>

          <h2>Datos personales que procesamos</h2>

          <p>Citasocasionales.es trata tus datos personales porque utilizas nuestros servicios y / o porque nos facilitas tus datos a través del formulario de contacto de nuestra web. A continuación, encontrará una descripción general de los datos personales que procesamos:</p>

          <ul>
            <li>Nombre y / o apellido, ingresado por usted mismo en el formulario de contacto;</li>

            <li>Dirección de correo electrónico, ingresada por usted mismo en el formulario de contacto;</li>

            <li>Dirección IP;</li>

            <li>Otros datos personales que proporcione activamente, como correspondencia;</li>

            <li>Datos sobre sus actividades en este sitio web;</li>

            <li>Datos sobre su comportamiento de navegación en diferentes sitios web;</li>

            <li>Navegador de Internet y tipo de dispositivo.</li>
          </ul>

          <h3>¿Con qué finalidad y sobre qué base procesamos los datos personales?</h3>

          <p>Citasocasionales.es trata sus datos personales con las siguientes finalidades:</p>

          <ul>
            <li>Para responder a los correos electrónicos que nos envíen a través del formulario de contacto;</li>

            <li>Citasocasionales.es analiza su comportamiento en el sitio web con el fin de mejorar el sitio web y adaptar la gama de productos y servicios a sus preferencias.</li>
          </ul>

          <h3>Política de privacidad para el uso de Google Analytics</h3>

          <p>A través de Citasocasionales.es, las cookies son lugares de Google, como parte de los servicios de “Analytics”. Usamos este servicio para realizar un seguimiento y obtener informes sobre cómo los visitantes usan el sitio web. Google puede proporcionar esta información a terceros si Google está legalmente obligado a hacerlo, o si terceros procesan la información en nombre de Google. No tenemos ninguna influencia en esto. No hemos permitido que Google utilice la información obtenida para mejorar otros servicios de Google. La información obtenida se utiliza exclusivamente dentro de otros servicios de Google con fines de análisis. No se proporciona su dirección IP. Lea la <a href="https://policies.google.com/privacy?hl=es" target="_blank" rel="noopener noreferrer">política de privacidad de Google</a> para obtener más información.</p>

          <p>Al utilizar este sitio web, usted da su consentimiento para que Google procese la información de la manera y para los fines descritos anteriormente.</p>

          <h3>Derechos sobre sus datos</h3>

          <p>Tienes derecho a inspeccionar, rectificar o suprimir los datos personales que Citasocasionales.es haya recibido de ti. También puede oponerse al procesamiento de sus datos personales (o parte de ellos) por nuestra parte. Además, tiene derecho a que los datos sobre usted que nos hayan sido transferidos a través de usted, de vuelta a usted mismo oa otra parte según sus instrucciones. Si previamente nos ha dado permiso para procesar sus datos personales, siempre tiene derecho a retirar este permiso.</p>

          <h3>Cookies</h3>

          <p>Citasocasionales.es también utiliza cookies. Estos son pequeños archivos que se almacenan en su computadora. La información que se almacena si utiliza Citasocasionales.es. Esto nos ayuda a mostrarle la información correcta e importante para usted como cliente.</p>

          <h3>Cookies de Google Analytics</h3>

          <p>Google Analytics se utiliza para almacenar cookies. Este es un programa de Google que se utiliza para leer todas las estadísticas. Mediante el uso de estas cookies Citasocasionales.es obtiene acceso a lo siguiente:</p>

          <h5>Números de visitantes</h5>

          <ul>
            <li>Qué páginas se visitan;</li>

            <li>El tiempo que un visitante pasa en un sitio web;</li>

            <li>Cookies técnicas.</li>
          </ul>

          <p>Para que funcione correctamente, es importante que existan cookies técnicas. Estas cookies aseguran que tenga una buena experiencia de usuario. Toda la información proporcionada es 100% anónima, lo que garantiza su privacidad. La legislación del Reglamento General de Protección de Datos (GDPR) ha indicado que los usuarios no tienen que dar permiso para el uso de cookies técnicas.</p>

          <h3>Cookies de marketing</h3>

          <p>Las cookies de marketing se utilizan para rastrear a un usuario en Internet. Cuando acepta el uso de cookies de marketing, Bestadultdating.co.uk coloca las llamadas cookies de seguimiento. Hacemos esto para poder presentarle información personalizada a través de canales en línea.</p>

          <h3>Seguimiento de cookies de anunciantes</h3>

          <p>Varios anunciantes de Citasocasionales.es colocan cookies de seguimiento en su computadora. Estas cookies se utilizan para los siguientes fines:</p>

          <ul>
            <li>Mantenga un registro de los sitios de contacto en los que se ha registrado;</li>

            <li>En qué banners se hace clic exactamente dentro de nuestro sitio web;</li>

            <li>Mantenga un registro de qué información es relevante para los visitantes y usuarios de nuestro sitio web.</li>
          </ul>

          <p>Es importante tener en cuenta que esta información es anónima y nunca se vinculará a una persona.</p>

          <h3>Quejas</h3>

          <p>Si tiene una queja sobre el procesamiento de sus datos personales o el uso de cookies, le pedimos que se comunique con nosotros de inmediato. Si no podemos encontrar una solución junto con usted, siempre tiene derecho a presentar una queja ante la Autoridad holandesa de protección de datos, que es la autoridad supervisora en el campo de la protección de la privacidad.</p>

          <h3>Preguntas</h3>

          <p>Si tiene alguna pregunta o comentario sobre nuestra declaración de privacidad, comuníquese con nosotros.</p>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'Privacy',
  props: ['sites', 'gaySites', 'getThisSite', 'getSiteLogoSource', 'getSiteImageSource', 'getTodayDate']
}
</script>
