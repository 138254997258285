<template>
  <TheHeader/>

  <TheOffcanvas/>

  <router-view
    :sites="getStraightSites"
    :gaySites="getGaySites"
    :getThisSite="getThisSite"
    :getSiteLogoSource="getSiteLogoSource"
    :getSiteImageSource="getSiteImageSource"
    :getTodayDate="getTodayDate"
  />

  <TheFooter/>

  <TheScrollTopButton/>

  <TheCookieNotice :cookieContent="cookieContent"/>
</template>

<script>
import siteOffers from '../data/site_offers.json'
import cookieContent from '../data/cookie_content.json'
import TheCookieNotice from '@/components/TheCookieNotice.vue'
import TheFooter from '@/components/TheFooter.vue'
import TheHeader from '@/components/TheHeader.vue'
import TheOffcanvas from '@/components/TheOffcanvas.vue'
import TheScrollTopButton from '@/components/TheScrollTopButton.vue'

export default {
  components: {
    TheCookieNotice,
    TheFooter,
    TheHeader,
    TheOffcanvas,
    TheScrollTopButton
  },
  data () {
    return {
      sites: siteOffers,
      cookieContent: cookieContent
    }
  },
  methods: {
    getThisSite (id) {
      const site = this.sites.find(site => site.id === id)
      return site
    },
    getSiteLogoSource (id) {
      const site = this.sites.find(site => site.id === id)
      const logoSource = site.logo_src
      return require(`@/assets/img/${logoSource}`)
    },
    getSiteImageSource (id) {
      const site = this.sites.find(site => site.id === id)
      const imageSource = site.img_src
      return require(`@/assets/img/${imageSource}`)
    }
  },
  computed: {
    getStraightSites () {
      const sites = this.sites.filter(site => site.tag === 'straight')
      return sites
    },
    getGaySites () {
      const sites = this.sites.filter(site => site.tag === 'gay')
      return sites
    },
    getTodayDate () {
      const today = new Date()
      const month = today.toLocaleString('es', { month: 'long' })
      const year = today.getFullYear()
      return `${month} ${year}`
    }
  },
  created () {
    const html = document.documentElement // returns the html tag
    html.setAttribute('lang', 'es')
  }
}
</script>
